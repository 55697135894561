import React, { useState, useEffect } from 'react';
import { 
  Drawer, 
  Typography, 
  Box, 
  TextField, 
  Button, 
  Grid, 
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { ClientDetails, PhoneNumber } from '../types/interfaces';

interface EditClientSidebarProps {
  open: boolean;
  onClose: () => void;
  clientDetails: ClientDetails | null;
  onUpdate: () => Promise<void>;
}

const EditClientSidebar: React.FC<EditClientSidebarProps> = ({
  open,
  onClose,
  clientDetails,
  onUpdate
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState<string | null>(null);
  const [editedClient, setEditedClient] = useState<ClientDetails | null>(null);
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);

  const fetchClientDetails = async (clientId: string) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`/api/clients/${clientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Nie udało się pobrać danych klienta');
      }

      const data = await response.json();
      setEditedClient(data);
      setPhoneNumbers(data.phoneNumbers.map((p: PhoneNumber) => p.phone));
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Wystąpił błąd');
    }
  };

  useEffect(() => {
    if (clientDetails?.id && open) {
      fetchClientDetails(clientDetails.id);
    }
  }, [clientDetails?.id, open]);

  const handleChange = (field: keyof ClientDetails, value: string | boolean) => {
    if (editedClient) {
      setEditedClient(prev => {
        if (!prev) return prev;
        return {
          ...prev,
          [field]: value
        };
      });
    }
  };

  const fetchCoordinates = async (address: string) => {
    const apiKey = "54cb09748c35400f81ae85366259c912";
    const encodedAddress = encodeURIComponent(address);
    const url = `https://api.geoapify.com/v1/geocode/search?text=${encodedAddress}&format=json&apiKey=${apiKey}`;

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Nie udało się pobrać współrzędnych');
    }

    const data = await response.json();
    if (data.results && data.results.length > 0) {
      const { lat, lon } = data.results[0];
      return { lat, lng: lon };
    } else {
      throw new Error('Brak wyników dla podanego adresu');
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!editedClient) return;

    try {
      const token = await getAccessTokenSilently();
      const coordinates = await fetchCoordinates(editedClient.address);

      const { 
        id, 
        lastServiceDate, 
        services, 
        created_at, 
        updated_at,
        ...dataToSubmit 
      } = {
        ...editedClient,
        lat: coordinates.lat,
        lng: coordinates.lng,
        phoneNumbers: phoneNumbers
          .filter(phone => phone.trim() !== '')
          .map(phone => ({ 
            phone,
            entity_type: 'client'
          }))
      };

      const response = await fetch(`/api/clients/${editedClient.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSubmit)
      });

      if (!response.ok) {
        throw new Error('Błąd podczas aktualizacji klienta');
      }

      await onUpdate();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Wystąpił błąd');
    }
  };

  const handleAddPhone = () => {
    setPhoneNumbers([...phoneNumbers, '']);
  };

  const handlePhoneChange = (index: number, value: string) => {
    const newPhones = [...phoneNumbers];
    newPhones[index] = value;
    setPhoneNumbers(newPhones);
  };

  const handleRemovePhone = (index: number) => {
    setPhoneNumbers(phoneNumbers.filter((_, i) => i !== index));
  };

  if (!editedClient) return null;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box sx={{ width: 400, p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Edycja danych klienta
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Imię i nazwisko"
                value={editedClient.name}
                onChange={(e) => handleChange('name', e.target.value)}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Typ instalacji"
                value={editedClient.serviceType}
                onChange={(e) => handleChange('serviceType', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Moc"
                value={editedClient.power}
                onChange={(e) => handleChange('power', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                multiline
                rows={3}
                label="Adres"
                value={editedClient.address}
                onChange={(e) => handleChange('address', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {phoneNumbers.map((phone, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                  <TextField
                    fullWidth
                    label={`Telefon ${index + 1}`}
                    value={phone}
                    onChange={(e) => handlePhoneChange(index, e.target.value)}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemovePhone(index)}
                  >
                    Usuń
                  </Button>
                </Box>
              ))}
              <Button
                variant="outlined"
                onClick={handleAddPhone}
                sx={{ mt: 1 }}
              >
                Dodaj numer telefonu
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(editedClient.resigned)}
                    onChange={(e) => {
                      console.log('Checkbox value:', e.target.checked);
                      handleChange('resigned', e.target.checked);
                    }}
                  />
                }
                label="Rezygnacja"
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <Button variant="outlined" onClick={onClose}>
                Anuluj
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Zapisz zmiany
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Drawer>
  );
};

export default EditClientSidebar;
