import React, {useEffect, useState} from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import {useAuth0} from '@auth0/auth0-react';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {ClientDetails, ServiceDetails} from '../types/interfaces';
import EditClientSidebar from './EditClientSidebar';

interface RightSidebarProps {
  open: boolean;
  onClose: () => void;
  clientDetails: ClientDetails;
  isEditMode: boolean;
  onEditClick: () => void;
}

const RightSidebar: React.FC<RightSidebarProps> = ({ open, onClose, clientDetails, isEditMode, onEditClick }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [serviceDetails, setServiceDetails] = useState<ServiceDetails[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedService, setEditedService] = useState<ServiceDetails | null>(null);
  const [expandedServiceId, setExpandedServiceId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchServiceDetails = async (clientId: string) => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const response = await fetch(`/api/services/client/${clientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        const servicesData = Array.isArray(data) ? data : [data];
        
        const processedServices = servicesData
          .filter(service => service && service.id)
          .map(serviceData => ({
            id: serviceData.id,
            clientId: serviceData.clientId,
            serviceDate: serviceData.service_date ? new Date(serviceData.service_date) : null,
            anodeDate: serviceData.anodeDate ? new Date(serviceData.anodeDate) : null,
            glycolDate: serviceData.glycolDate ? new Date(serviceData.glycolDate) : null,
            personResponsible: serviceData.person_responsible || '',
            amount: serviceData.amount ? parseFloat(serviceData.amount) : 0,
            scope: serviceData.scope || '',
            notes: serviceData.notes || '',
            createdAt: serviceData.createdAt ? new Date(serviceData.createdAt) : new Date(),
            updatedAt: serviceData.updatedAt ? new Date(serviceData.updatedAt) : new Date(),
            serviceDone: serviceData.service_done || 0
          }))
          .sort((a, b) => {
            if (!a.serviceDate || !b.serviceDate) return 0;
            return b.serviceDate.getTime() - a.serviceDate.getTime();
          });

        setServiceDetails(processedServices);
        setEditedService(null);
      }
    } catch (error) {
      setError('Błąd podczas pobierania danych serwisowych');
      setServiceDetails([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchClientDetails = async (clientId: string) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`/api/clients/${clientId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      setError('Błąd podczas pobierania danych klienta');
    }
    return null;
  };

  const handleCreateService = async () => {
    if (!editedService || !clientDetails?.id) return;

    try {
      const token = await getAccessTokenSilently();
      
      const { id, ...serviceDataWithoutId } = editedService;
      
      const dataToSend = {
        client_id: clientDetails.id,
        service_date: editedService.serviceDate?.toISOString(),
        anode_date: editedService.anodeDate?.toISOString(),
        glycol_date: editedService.glycolDate?.toISOString(),
        person_responsible: editedService.personResponsible,
        amount: editedService.amount,
        scope: editedService.scope,
        notes: editedService.notes,
      };

      const response = await fetch('/api/services', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      });

      if (!response.ok) {
        throw new Error('Nie udało się utworzyć przeglądu');
      }

      await fetchServiceDetails(clientDetails.id);
      setIsEditing(false);
      setEditedService(null);
    } catch (error) {
      console.error('Błąd podczas tworzenia przeglądu:', error);
    }
  };

  const handleUpdateService = async () => {
    if (!editedService?.id || !clientDetails?.id) return;
    
    try {
      const token = await getAccessTokenSilently();
      const { id, clientId, createdAt, updatedAt, ...serviceDataWithoutIds } = editedService;
      
      const dataToSend = {
        client_id: clientDetails.id,
        service_date: editedService.serviceDate?.toISOString(),
        anode_date: editedService.anodeDate?.toISOString(),
        glycol_date: editedService.glycolDate?.toISOString(),
        person_responsible: editedService.personResponsible,
        amount: editedService.amount,
        scope: editedService.scope,
        notes: editedService.notes,
        service_done: editedService.serviceDone ? 1 : 0
      };

      const response = await fetch(`/api/services/${editedService.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      });

      if (response.ok) {
        await fetchServiceDetails(clientDetails.id);
        setIsEditing(false);
        setEditedService(null);
      } else {
        throw new Error('Nie udało się zaktualizować przeglądu');
      }
    } catch (error) {
      console.error('Błąd podczas aktualizacji przeglądu:', error);
    }
  };

  useEffect(() => {
    if (open && clientDetails?.id) {
      const updateClientData = async () => {
        const freshClientData = await fetchClientDetails(clientDetails.id);
        if (freshClientData) {
          // Aktualizuj dane serwisowe
          await fetchServiceDetails(clientDetails.id);
        }
      };
      updateClientData();
    }
  }, [open, clientDetails?.id]);

  const renderServiceForm = () => (
    <form onSubmit={(e) => {
      e.preventDefault();
      if (editedService?.id) {
        handleUpdateService();
      } else {
        handleCreateService();
      }
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Data przeglądu"
            type="date"
            value={editedService?.serviceDate?.toISOString().split('T')[0] || ''}
            onChange={(e) => setEditedService(prev => prev ? {
              ...prev,
              serviceDate: new Date(e.target.value)
            } : null)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Data wymiany anody"
            type="date"
            value={editedService?.anodeDate?.toISOString().split('T')[0] || ''}
            onChange={(e) => setEditedService(prev => prev ? {
              ...prev,
              anodeDate: e.target.value ? new Date(e.target.value) : null
            } : null)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Data wymiany glikolu"
            type="date"
            value={editedService?.glycolDate?.toISOString().split('T')[0] || ''}
            onChange={(e) => setEditedService(prev => prev ? {
              ...prev,
              glycolDate: e.target.value ? new Date(e.target.value) : null
            } : null)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Osoba odpowiedzialna"
            value={editedService?.personResponsible || ''}
            onChange={(e) => setEditedService(prev => prev ? {
              ...prev,
              personResponsible: e.target.value
            } : null)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Kwota"
            type="number"
            value={editedService?.amount || 0}
            onChange={(e) => setEditedService(prev => prev ? {
              ...prev,
              amount: parseFloat(e.target.value)
            } : null)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Zakres"
            value={editedService?.scope || ''}
            onChange={(e) => setEditedService(prev => prev ? {
              ...prev,
              scope: e.target.value
            } : null)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(editedService?.serviceDone)}
                onChange={(e) => setEditedService(prev => prev ? {
                  ...prev,
                  serviceDone: e.target.checked ? 1 : 0
                } : null)}
              />
            }
            label="Serwis wykonany"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Notatki"
            multiline
            rows={2}
            value={editedService?.notes || ''}
            onChange={(e) => setEditedService(prev => prev ? {
              ...prev,
              notes: e.target.value
            } : null)}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={() => {
            setIsEditing(false);
            setEditedService(null);
          }}>
            Anuluj
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {editedService?.id ? 'Zapisz zmiany' : 'Dodaj przegląd'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  const handleEditClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onEditClick();
  };

  if (isEditMode) {
    return (
      <EditClientSidebar
        open={open}
        onClose={onClose}
        clientDetails={clientDetails}
        onUpdate={async () => {
          await fetchServiceDetails(clientDetails.id);
        }}
      />
    );
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box sx={{ width: 400, p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Szczegóły klienta</Typography>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Dane klienta
              </Typography>
              <Typography variant="body1">
                {clientDetails?.name}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Tel: {clientDetails?.phoneNumbers?.join(', ') || '-'}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {clientDetails?.address}
              </Typography>
            </Box>

            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                Historia przeglądów
              </Typography>
              {!isEditing && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsEditing(true);
                    setEditedService({
                      id: '',
                      serviceDate: null,
                      anodeDate: null,
                      glycolDate: null,
                      personResponsible: '',
                      amount: 0,
                      scope: '',
                      notes: '',
                      clientId: clientDetails?.id || '',
                      serviceDone: 0,
                      createdAt: new Date(),
                      updatedAt: new Date()
                    });
                  }}
                >
                  Dodaj przegląd
                </Button>
              )}
            </Box>

            {isEditing ? (
              renderServiceForm()
            ) : (
              // Lista przeglądów
              <List>
                {serviceDetails.map((service) => (
                  <Paper key={service.id} sx={{ mb: 2 }}>
                    <ListItemButton 
                      onClick={() => setExpandedServiceId(
                        expandedServiceId === service.id ? null : service.id
                      )}
                    >
                      <ListItemText
                        primary={service.serviceDate?.toLocaleDateString()}
                        secondary={`Odpowiedzialny: ${service.personResponsible}`}
                      />
                      {expandedServiceId === service.id ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={expandedServiceId === service.id} timeout="auto" unmountOnExit>
                      <Box sx={{ p: 2 }}>
                        <Typography variant="body2" gutterBottom>
                          <strong>Kwota:</strong> {service.amount} zł
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong>Zakres:</strong> {service.scope}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong>Notatki:</strong> {service.notes}
                        </Typography>
                        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              setIsEditing(true);
                              setEditedService(service);
                            }}
                          >
                            Edytuj
                          </Button>
                        </Box>
                      </Box>
                    </Collapse>
                  </Paper>
                ))}
              </List>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default RightSidebar;
