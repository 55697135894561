import React, {useState} from 'react';
import {Alert, Box, Button, Grid, Paper, SelectChangeEvent, Snackbar, TextField, Typography} from '@mui/material';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

interface FormData {
    name: string;
    address: string;
    phoneNumbers: string[];
    power: string;
    installationDate: string;
    agreementDate: string;
    personResponsible: string;
    serviceType: string;
}

const NowyFormularz: React.FC = () => {
    const {getAccessTokenSilently} = useAuth0();
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormData>({
        name: '',
        address: '',
        phoneNumbers: [''],
        power: '',
        installationDate: '',
        agreementDate: '',
        personResponsible: '',
        serviceType: ''
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success' as 'success' | 'error'
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {
        name?: string;
        value: unknown
    }>) => {
        const {name, value} = event.target;
        setFormData(prev => ({
            ...prev,
            [name as string]: value
        }));
    };

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const {name, value} = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const fetchCoordinates = async (address: string) => {
        const apiKey = "54cb09748c35400f81ae85366259c912";
        const encodedAddress = encodeURIComponent(address);
        const url = `https://api.geoapify.com/v1/geocode/search?text=${encodedAddress}&format=json&apiKey=${apiKey}`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Nie udało się pobrać współrzędnych');
        }

        const data = await response.json();
        if (data.results && data.results.length > 0) {
            const {lat, lon} = data.results[0];
            return {lat, lng: lon};
        } else {
            throw new Error('Brak wyników dla podanego adresu');
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            if (!Array.isArray(formData.phoneNumbers)) {
                throw new Error('phoneNumbers nie jest tablicą');
            }

            const installationDate = new Date(formData.installationDate);
            const agreementDate = new Date(formData.agreementDate);

            if (isNaN(installationDate.getTime()) || isNaN(agreementDate.getTime())) {
                throw new Error('Nieprawidłowy format daty');
            }

            const coordinates = await fetchCoordinates(formData.address);

            const dataToSubmit = {
                name: formData.name,
                address: formData.address,
                phoneNumbers: formData.phoneNumbers
                    .filter(phone => phone.trim() !== '')
                    .map(phone => ({
                        phone,
                        entity_type: 'client'
                    })),
                power: formData.power,
                installationDate: installationDate.toISOString(),
                agreementDate: agreementDate.toISOString(),
                personResponsible: formData.personResponsible,
                lat: coordinates.lat,
                lng: coordinates.lng,
                serviceType: formData.serviceType
            };

            const token = await getAccessTokenSilently();

            const response = await fetch('/api/clients', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(dataToSubmit)
            });

            if (!response.ok) {
                throw new Error('Błąd podczas dodawania klienta');
            }

            setSnackbar({
                open: true,
                message: 'Klient został dodany pomyślnie',
                severity: 'success'
            });

            setTimeout(() => {
                navigate('/mapa?client_traits=not_resigned,overdue_or_soon');
            }, 1000);

        } catch (error) {
            console.error('Błąd podczas zapisywania:', error);
            setSnackbar({
                open: true,
                message: 'Wystąpił błąd podczas dodawania klienta',
                severity: 'error'
            });
        }
    };

    const handleAddPhone = () => {
        setFormData(prev => ({
            ...prev,
            phoneNumbers: [...prev.phoneNumbers, '']
        }));
    };

    const handlePhoneChange = (index: number, value: string) => {
        setFormData(prev => {
            const newPhones = [...prev.phoneNumbers];
            newPhones[index] = value;
            return {
                ...prev,
                phoneNumbers: newPhones
            };
        });
    };

    const handleRemovePhone = (index: number) => {
        setFormData(prev => ({
            ...prev,
            phoneNumbers: prev.phoneNumbers.filter((_, i) => i !== index)
        }));
    };

    return (
        <Box sx={{maxWidth: 800, margin: 'auto', pt: 4}}>
            <Paper elevation={3} sx={{p: 4}}>
                <Typography variant="h4" sx={{mb: 4}}>Nowy klient</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="Imię i nazwisko"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {formData.phoneNumbers.map((phone, index) => (
                                <Box key={index} sx={{display: 'flex', gap: 1, mb: 1}}>
                                    <TextField
                                        fullWidth
                                        label={`Telefon ${index + 1}`}
                                        value={phone}
                                        onChange={(e) => handlePhoneChange(index, e.target.value)}
                                    />
                                    {formData.phoneNumbers.length > 1 && (
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() => handleRemovePhone(index)}
                                        >
                                            Usuń
                                        </Button>
                                    )}
                                </Box>
                            ))}
                            <Button
                                variant="outlined"
                                onClick={handleAddPhone}
                                sx={{mt: 1}}
                            >
                                Dodaj numer telefonu
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Typ instalacji"
                                name="serviceType"
                                value={formData.serviceType}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="Moc"
                                name="power"
                                value={formData.power}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                multiline
                                rows={3}
                                label="Adres"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="Data instalacji"
                                type="date"
                                name="installationDate"
                                value={formData.installationDate}
                                onChange={handleChange}
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="Data podpisania umowy"
                                type="date"
                                name="agreementDate"
                                value={formData.agreementDate}
                                onChange={handleChange}
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                required
                                label="Osoba odpowiedzialna"
                                name="personResponsible"
                                value={formData.personResponsible}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Dodaj klienta
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({...prev, open: false}))}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({...prev, open: false}))}
                    severity={snackbar.severity}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default NowyFormularz;
