import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { MapContainer, TileLayer, CircleMarker, Popup, useMap } from 'react-leaflet';
import * as L from 'leaflet';
import { ClientDetails, MarkerColor } from '../types/interfaces';
import RightSidebar from './RightSidebar';
import { useAuth0 } from '@auth0/auth0-react';
import 'leaflet/dist/leaflet.css';
import { useLocation, useNavigate } from 'react-router-dom';
import EditClientSidebar from './EditClientSidebar';
import { useFilters } from '../contexts/FiltersContext';

// Naprawiamy problem z ikoną markera
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

interface MapComponentProps {
  klienci: ClientDetails[];
  onMarkerClick: (client: ClientDetails) => void;
  onEditClick: (client: ClientDetails) => void;
}

const MapComponent: React.FC<MapComponentProps> = React.memo(({ klienci, onMarkerClick, onEditClick }) => {
  const map = useMap();
  const [activePopup, setActivePopup] = useState<string | null>(null);
  const { serviceFilters } = useFilters();
  const [filteredKlienci, setFilteredKlienci] = useState(klienci);
  const filterWorkerRef = useRef<Worker | null>(null);
  const location = useLocation();

  useEffect(() => {
    filterWorkerRef.current = new Worker(new URL('../workers/filterWorker.ts', import.meta.url));
    
    filterWorkerRef.current.onmessage = (e: MessageEvent) => {
      setFilteredKlienci(e.data);
    };

    return () => {
      filterWorkerRef.current?.terminate();
    };
  }, []);

  useEffect(() => {
    if (filterWorkerRef.current) {
      const queryParams = new URLSearchParams(location.search);
      const clientTraits = queryParams.get('client_traits')?.split(',') || [];
      const name = queryParams.get('name') || '';
      const phone = queryParams.get('phone') || '';

      filterWorkerRef.current.postMessage({ 
        klienci,
        serviceFilters: {
          overdueOrSoon: clientTraits.includes('overdue_or_soon'),
          overdue: clientTraits.includes('overdue'),
          current: clientTraits.includes('current'),
          resigned: clientTraits.includes('resigned'),
          not_resigned: clientTraits.includes('not_resigned'),
          name,
          phone
        } 
      });
    }
  }, [klienci, location.search]);

  const canvasRenderer = useMemo(() => L.canvas({ padding: 0.5 }), []);

  const markers = useMemo(() => {
    const iconColors: Record<MarkerColor, string> = {
      resigned: '#808080',    // szary
      red: '#ff4444',        // czerwony
      yellow: '#ffbb33',     // żółty
      lightyellow: '#fff176', // jasny żółty
      green: '#00C851',      // zielony
      default: '#2196F3'     // niebieski
    };

    return filteredKlienci
      .filter(klient => klient.lat && klient.lng)
      .map(klient => {
        const color = (klient.color || 'default') as MarkerColor;

        return (
          <CircleMarker
            key={klient.id}
            center={[klient.lat, klient.lng]}
            radius={8}
            pathOptions={{
              renderer: canvasRenderer,
              color: iconColors[color],
              fillColor: iconColors[color],
              fillOpacity: 0.7,
              weight: 2
            }}
            eventHandlers={{
              click: (e: any) => {
                e.originalEvent.stopPropagation();
                setActivePopup(klient.id);
              }
            }}
          >
            <Popup
              closeButton={true}
              autoPan={true}
              onClose={() => setActivePopup(null)}
            >
              <Box sx={{ p: 1 }}>
                <Typography variant="h6">{klient.name}</Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Adres:</strong> {klient.address}
                </Typography>
                {klient.phoneNumbers && klient.phoneNumbers.length > 0 && (
                  <Typography variant="body2" gutterBottom>
                    <strong>Telefon:</strong> {Array.isArray(klient.phoneNumbers) ? klient.phoneNumbers.join(', ') : klient.phoneNumbers}
                  </Typography>
                )}
                {klient.power && (
                  <Typography variant="body2" gutterBottom>
                    <strong>Moc:</strong> {klient.power}
                  </Typography>
                )}
                <Typography variant="body2" gutterBottom>
                  <strong>Typ:</strong> {klient.serviceType || 'Brak'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Status:</strong> {klient.resigned ? 'Rezygnacja' : 'Aktywny'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Data instalacji:</strong> {klient.installationDate ? new Date(klient.installationDate).toLocaleDateString('pl-PL') : 'Brak'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Ostatni przegląd:</strong> {klient.lastServiceDate ? new Date(klient.lastServiceDate).toLocaleDateString('pl-PL') : 'Brak'}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onMarkerClick(klient);
                    }}
                  >
                    Szczegóły
                  </Button>
                </Box>
              </Box>
            </Popup>
          </CircleMarker>
        );
      });
  }, [filteredKlienci, canvasRenderer, onMarkerClick]);

  return <>{markers}</>;
}, (prevProps, nextProps) => {
  return prevProps.klienci === nextProps.klienci;
});

const ListaKlientow: React.FC = React.memo(() => {
  const [loading, setLoading] = useState(true);
  const [klienci, setKlienci] = useState<ClientDetails[]>([]);
  const [filteredKlienci, setFilteredKlienci] = useState<ClientDetails[]>([]);
  const filterWorkerRef = useRef<Worker | null>(null);
  const location = useLocation();
  const [selectedClient, setSelectedClient] = useState<ClientDetails | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  // Inicjalizacja workera tylko raz
  useEffect(() => {
    filterWorkerRef.current = new Worker(new URL('../workers/filterWorker.ts', import.meta.url));
    
    return () => {
      filterWorkerRef.current?.terminate();
    };
  }, []);

  // Zdefiniowanie fetchClients jako useCallback
  const fetchClients = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('/api/clients', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      const clientsData = Array.isArray(data) ? data : Object.values(data);
      setKlienci(clientsData);
      setFilteredKlienci(clientsData);
      setLoading(false);
    } catch (error) {
      console.error('Błąd podczas pobierania klientów:', error);
      setKlienci([]);
      setFilteredKlienci([]);
      setLoading(false);
    }
  }, [getAccessTokenSilently]);

  // Pobieranie klientów przy montowaniu komponentu
  useEffect(() => {
    let isMounted = true;
    
    const loadClients = async () => {
      if (isMounted) {
        await fetchClients();
      }
    };

    loadClients();
    return () => {
      isMounted = false;
    };
  }, [fetchClients]);

  const handleEditClick = useCallback(() => {
    console.log('handleEditClick wywołane');
    setIsEditMode(true);
  }, []);

  const handleClientClick = useCallback((client: ClientDetails) => {
    console.log('handleClientClick wywołane dla:', client.id);
    setSelectedClient(client);
    setSidebarOpen(true);
    setIsEditMode(false);
  }, []);

  // Komponent mapy wydzielony jako osobny komponent z memo
  const MapWithMarkers = useMemo(() => (
    <MapContainer
      center={[52.069167, 19.480556]}
      zoom={6}
      style={{ height: '100%', width: '100%' }}
      preferCanvas={true}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapComponent 
        klienci={filteredKlienci} 
        onMarkerClick={handleClientClick}
        onEditClick={handleClientClick}
      />
    </MapContainer>
  ), [filteredKlienci, handleClientClick]);

  const renderSidebars = useMemo(() => (
    <>
      {!isEditMode && selectedClient && (
        <RightSidebar
          open={sidebarOpen}
          onClose={() => {
            setSidebarOpen(false);
            setSelectedClient(null);
            setIsEditMode(false);
          }}
          clientDetails={selectedClient}
          isEditMode={isEditMode}
          onEditClick={handleEditClick}
        />
      )}
      {isEditMode && selectedClient && (
        <EditClientSidebar
          open={sidebarOpen}
          onClose={() => {
            setSidebarOpen(false);
            setSelectedClient(null);
            setIsEditMode(false);
          }}
          clientDetails={selectedClient}
          onUpdate={async () => {
            await fetchClients();
            setIsEditMode(false);
            setSidebarOpen(false);
            setSelectedClient(null);
          }}
        />
      )}
    </>
  ), [isEditMode, selectedClient, sidebarOpen, handleEditClick, fetchClients]);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        {loading ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%' 
          }}>
            <CircularProgress />
          </Box>
        ) : (
          MapWithMarkers
        )}
      </Box>
      {renderSidebars}
    </Box>
  );
}, (prevProps, nextProps) => true); // Zawsze zwracaj true, bo komponent nie ma propsów

export default ListaKlientow;
